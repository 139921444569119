:root {
  --primary: #2352ac;
  --primary-tint80: #2352ac80;
  --primary-tint60: #8bc0fe;
  --primary-tint40: #2352ac40;
  --primary-tint20: #2352ac20;

  --secondary: #d7d5d5;

  --white: #ffffff;
  --dark-gray: #858585;
  --dark-gray-02: #616161;
  --black: #1b1c1e;

  --system-gray-01: #8e8e93;
  --system-gray-02: #aeaeb2;
  --system-gray-03: #c7c7cc;
  --system-gray-04: #d1d1d6;
  --system-gray-05: #e5e5ea;
  --system-gray-06: #f2f2f7;

  --system-gray-06-50: #f5f5f8;

  --system-gray-300: #f4f7fe;

  --keyColor-01: #03315b;
  --keyColor-01-40: rgba(35, 82, 172, 0.4);
  --keyColor-01-60: #7b97cd;
  --system-gray-04-40: rgba(209, 209, 214, 0.4);

  --error: #f31515;
  --warning: #ffc800;
  --success: #00c814;

  /* button color */
  --order-navy: #03315b;
  --order-yellow: #f7a300;
  --order-green: #008205;
  --order-red: #d75821;
  --order-blue: #0072d8;

  font-size: 62.5%;
}
