* {
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100vh;
  max-width: 100vw;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 12px;
}

body {
  padding: 0;
  min-height: 100vh;
  width: 100%;
  min-width: fit-content;
  max-width: 100vw;
  margin: 0;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li {
  list-style: none;
  padding-left: 0;
}
a {
  text-decoration: none;
}
button {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
}

fieldset {
  border: none;
}
pre {
  font-family: 'Pretendard';
}

a:focus,
select:focus,
button:focus,
input[type='checkbox']:focus,
input[type='radio']:focus,
ul:focus,
li:focus {
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/* [data-rmiz-modal-overlay='visible'] {
  background-color: rgba(0, 0, 0, 0.237) !important;
} */
